import React from 'react'

const App = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-purple-300">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-4">Coming Soon</h1>
        <p className="text-lg text-gray-600">
          We are working hard to bring you a great experience!
        </p>
      </div>
    </div>
  );
}

export default App